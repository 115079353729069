import React, { useRef } from 'react';
import clsx from 'clsx';

/**
 * A component for buttons.
 *
 * @typedef Button
 * @kind functional component
 *
 * @param {props} props React component props
 *
 * @returns {React.Element} A React component that displays a single button.
 */

type ButtonProps = {
  children: React.ReactNode;
  className?: string;
  classes?: Record<string, string>;
  priority?: string,
  negative?: boolean;
  disabled?: boolean;
  onClick?: (parameter?:any) => any;
  p?: string;
  type?: string;
  id?: string
  value?: string
};

const Button:React.FC<ButtonProps> = props => {
  const {
    children,
    className,
    classes: propClasses,
    priority,
    negative,
    disabled,
    onClick,
    type = 'button',
    p = 'px-6',
    ...restProps
  } = props;
  const buttonRef = useRef();

  return (
    <button
      ref={buttonRef}
      onClick={onClick}
      disabled={disabled}
      {...restProps}
      className={clsx(
        'h-10 items-center justify-center font-bold disabled:text-[#acacac] disabled:opacity-75 disabled:cursor-not-allowed',
        className,
        p,
      )}
    >
      {children}
    </button>
  );
};


export default Button;
