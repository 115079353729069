import { gql } from '@apollo/client';

export const NOT_FOUND_PAGE = gql`
query get404Page($identifiers: [String]!) {
    cmsBlocks(identifiers: $identifiers) {
      items {
        content
        identifier
        title
      }
    }
  }
`;