import { useEffect } from 'react';
export const useClarityScript = (shouldLoad, clarityScripts) => {
  useEffect(() => {
    if (shouldLoad) {
      window.clarity =
        window.clarity ||
        function (...args) {
          (window.clarity.q = window.clarity.q || []).push(args);
        };
      const baseScript = document.createElement('script');
      baseScript.async = true;
      baseScript.src = clarityScripts?.[0];
      baseScript.id = 'clarity-base-script';
      document.head.appendChild(baseScript);
      const tagScript = document.createElement('script');
      tagScript.async = true;
      tagScript.src = clarityScripts?.[1];
      tagScript.id = 'clarity-tag-script';
      document.head.appendChild(tagScript);
      return () => {
        const baseScriptElement = document.getElementById(
          'clarity-base-script',
        );
        const tagScriptElement = document.getElementById('clarity-tag-script');
        if (baseScriptElement) {
          baseScriptElement.remove();
        }
        if (tagScriptElement) {
          tagScriptElement.remove();
        }
        delete window.clarity;
      };
    }
  }, [shouldLoad]);
};
