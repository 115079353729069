import { useState, useEffect } from "react";

const useTimeBasedToggle = () => {
  const [isJoinLiveDemoVisible, setJoinLiveDemoVisible] = useState(false);

  useEffect(() => {
    const checkTime = () => {
      const currentISTTime = new Date(
        new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
      );

      const hours = currentISTTime.getHours();
      setJoinLiveDemoVisible(hours >= 10 && hours < 19);
    };

    checkTime();

    const interval = setInterval(() => {
      checkTime();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return isJoinLiveDemoVisible;
};

export default useTimeBasedToggle;
