import { gql } from '@apollo/client';

export const GET_MARKETING_STRIP = gql`
  query getHomePageTickerContent {
    getHomePageTickerContent {
      ticker_content
      ticker_url
    }
  }
`;
