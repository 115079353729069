import { use404Page } from 'src/hooks/404Page/use404Page';
import styles from './404.style.module.scss';
import LoadingIndicator from 'packages/framework/components/LoadingIndicator/indicator';

export default function Page404() {
  const { cmsData, loading } = use404Page();

  return (
    <div className={styles.error404Wrapper}>
      <div className={styles.container}>
        <div className={styles.error404Header}>
          <h1>Sorry, that page doesn't exist! </h1>
          <h2 className={styles.error404}>404</h2>
          <img src="/images/sp-404-sad-face.png" />
          <p>Page not found!</p>
        </div>
        {!loading ? <div className={styles.editor404Data}>
          <div dangerouslySetInnerHTML={{ __html: cmsData }} />
        </div> : <LoadingIndicator />}
      </div>
    </div>
  );
}
