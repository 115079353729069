import { sendGtmEvent } from 'packages/util/gtm';

const setRequestUrl = (refs, url) => {
  refs?.forEach(ref => {
    if (ref) {
      ref.href = url;
    }
  });
}

export const createRedirectUrlWithUtmParams = (baseUrl, sku, refs) => {
  if (!baseUrl || !baseUrl.includes('https://')) return null;
  const utmParams = [
    'lt_utm_source',
    'lt_utm_medium',
    'lt_utm_campaign',
    'lt_utm_term',
    'lt_utm_content',
  ];
  let gtmParams = { SKU: sku };
  const url = new URL(baseUrl);

  const normalizedRefs = Array.isArray(refs)
    ? refs.map(ref => (ref?.current ? ref.current : ref))
    : [refs?.current || refs];

  utmParams.forEach(param => {
    const value = localStorage.getItem(param);
    const utmGtmEventAttribute = param.replace('lt_', '');
    gtmParams[utmGtmEventAttribute] = value || '';
    if (value) {
      url.searchParams.set(utmGtmEventAttribute, value);
    }
  });

  setRequestUrl(normalizedRefs, url)
  return { url: url.href, gtmParams };
};

export const handleRequestLiveDemoRedirection = (
  redirectLink,
  sku = '',
  eventName,
  refs,
  REQUEST_LIVE_DEMO_CLICKED = false,
) => {
  const { gtmParams } = createRedirectUrlWithUtmParams(redirectLink, sku, refs);

  if (REQUEST_LIVE_DEMO_CLICKED)
    sendGtmEvent(eventName, {
      ...gtmParams,
    });

};

export default handleRequestLiveDemoRedirection;
