import { useWindowSize } from 'packages/framework/hooks/useWindowSize';
import { NOT_FOUND_PAGE } from './404Page.gql';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

export const use404Page = () => {
  const { isMobile } = useWindowSize();
  const { data, loading } = useQuery(NOT_FOUND_PAGE, {
    fetchPolicy: 'no-cache',
    variables: { identifiers: ['404_page'] },
  });
  
const cmsData = useMemo(() => {
    let cmsData = data?.cmsBlocks?.items[0]?.content;
      try {
        const parser = new DOMParser();
        const doc = parser.parseFromString(cmsData, 'text/html');
        if (isMobile) {
          doc.querySelector('.pagebuilder-mobile-hidden')?.remove();
        } else {
          doc.querySelector('.pagebuilder-mobile-only')?.remove();
        }
        cmsData = doc.body.innerHTML;
      } catch (error) {
        console.error('Error parsing HTML content:', error);
        cmsData = '';
      }
    return cmsData
  },[data])

  
  return { cmsData, loading };
};
