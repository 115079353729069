import { useEffect, useMemo, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { useWindowSize } from 'packages/framework/hooks/useWindowSize';
import { Link } from 'react-router-dom';
import { getMarketingTickerContent } from 'src/hooks/MarketingStrip/marketingStrip';
import 'swiper/swiper-bundle.css';
import './style.scss';

const MarketingTickerStrip = () => {
  const slideRefs = useRef([]);
  const swiperRef = useRef(null);
  const { data } = getMarketingTickerContent();
  const { isDesktop } = useWindowSize();
  const { ticker_backgrount_color, ticker_font_color, ticker_speed } =
    STORE_CONFIG || {};

  const calculateDuration = (element, container) => {
    if (!element || !container) return 0;
    const textWidth = element.offsetWidth;
    const containerWidth = container.offsetWidth;
    const totalDistance = textWidth + containerWidth;
    const speed = 15; // adjust speed
    const finalSpeed = isDesktop
      ? (totalDistance * ticker_speed) / 2
      : totalDistance * ticker_speed;
    return finalSpeed;
  };

  const handleSlideAnimation = swiper => {
    slideRefs.current.forEach(slideRef => {
      if (slideRef) {
        slideRef.style.animation = 'none';
        slideRef.style.opacity = '0';
      }
    });

    const activeSlide = slideRefs.current[swiper.activeIndex];
    if (activeSlide) {
      const container = activeSlide.parentElement;
      const duration = calculateDuration(activeSlide, container);
      // Force reflow
      void activeSlide.offsetWidth;

      // Apply animation
      activeSlide.style.opacity = '1';
      activeSlide.style.animation = `ticker ${duration}ms linear infinite`;
      if (swiper && swiper.params && swiper.autoplay) {
        swiper.params.autoplay.delay = duration;
        swiper.autoplay.start();
      }
    }
  };

  useEffect(() => {
    if (slideRefs?.current?.length) {
      swiperRef?.current.autoplay.stop();
      slideRefs.current[0].style.opacity = '0';

      setTimeout(() => {
        swiperRef?.current.autoplay.start();
        slideRefs.current[0].style.opacity = '1';
      }, 2000);

      const activeSlide = slideRefs.current[0];
      if (activeSlide) {
        const container = activeSlide.parentElement;
        const duration = calculateDuration(activeSlide, container);
        void activeSlide.offsetWidth;
        // Apply animation
        activeSlide.style.animation = `ticker ${duration}ms linear infinite`;
        if (
          swiperRef?.current &&
          swiperRef?.current.params &&
          swiperRef?.current.autoplay
        ) {
          swiperRef.current.params.autoplay.delay = duration;
          swiperRef?.current.autoplay.start();
        }
      }
    }
  }, [slideRefs, data]);


  return (
    <div
      className={`marketingTickerStrip ${
        ticker_backgrount_color ? `bg-[${ticker_backgrount_color}]` : 'bg-brand'
      } ${data?.getHomePageTickerContent?.length ? 'block' : 'hidden' } `}
    >
      <Swiper
        modules={[Autoplay]}
        autoplay={{ delay: 0, disableOnInteraction: false }}
        slidesPerView={1}
        slidesPerGroup={1}
        onSwiper={swiper => {
          swiperRef.current = swiper;
          handleSlideAnimation(swiper);
        }}
        onSlideChange={handleSlideAnimation}
      >
        {data?.getHomePageTickerContent?.map((item, index) => (
          <SwiperSlide key={index}>
            <Link
              to={item?.ticker_url ? item?.ticker_url : '#'}
              ref={el => (slideRefs.current[index] = el)}
              className={`textItem ${
                ticker_font_color ? `text-[${ticker_font_color}]` : 'text-black'
              }`}
            >
              {item?.ticker_content}
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default MarketingTickerStrip;
